var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState, } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import Alert from "react-s-alert";
import { Text } from "../../../text";
import { Button } from "../../../button";
import { isUrl, isDataUrl } from "../../../urlUtils";
import { FieldLabel } from "../FieldLabel";
import { convertImgToBase64URL } from "./utils";
import { ButtonLayoutWrapper, DisplayWrapper, Header, PlaceholderMessage, SignatureCanvasContainer, SignatureContainer, } from "./styles";
import { EmptySignatureMessage } from "./EmptySignatureMessage";
export var Signature = function (_a) {
    var value = _a.value, label = _a.label, disabled = _a.disabled, testId = _a.testId, options = _a.options, transformUrl = _a.transformUrl, onChange = _a.onChange, onError = _a.onError;
    var isEmpty = !value;
    var _b = useState(false), hasError = _b[0], setHasError = _b[1];
    var _c = useState(new Date().toISOString()), retryAt = _c[0], setRetryAt = _c[1];
    var _d = useState(false), isTouched = _d[0], setIsTouched = _d[1];
    var signatureRef = useRef();
    var onChangeRef = useRef(onChange);
    useEffect(function () {
        onChangeRef.current = onChange;
    }, [onChange]);
    // HACK: we need event listeners just once, but the onChange callback must be always latest, so we abuse ref.
    useEffect(function () {
        if (signatureRef.current) {
            var signaturePad_1 = signatureRef.current.instance;
            var canvasRef = signatureRef.current.canvas;
            signaturePad_1.addEventListener("beginStroke", function () {
                setIsTouched(true);
            });
            signaturePad_1.addEventListener("endStroke", function () {
                var _a, _b;
                setIsTouched(false);
                onChangeRef.current((_b = (_a = signatureRef.current) === null || _a === void 0 ? void 0 : _a.toDataURL()) !== null && _b !== void 0 ? _b : "");
            });
            // Moving the mouse out of canvas does not fire endStroke event by default so we force it
            canvasRef.current.addEventListener("mouseleave", function () {
                var hasStroke = signaturePad_1.toData().length > 0;
                if (hasStroke) {
                    signaturePad_1.dispatchEvent(new Event("endStroke"));
                }
            });
        }
    }, []);
    useEffect(function () {
        var initializeCanvas = function () { return __awaiter(void 0, void 0, void 0, function () {
            var dataURL, error_1, message;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!isDataUrl(value)) return [3 /*break*/, 1];
                        dataURL = value;
                        return [3 /*break*/, 5];
                    case 1:
                        if (!isUrl(value)) return [3 /*break*/, 5];
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, convertImgToBase64URL(transformUrl(value))];
                    case 3:
                        dataURL = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _b.sent();
                        setHasError(true);
                        message = "Unable to load signature, please try to reload.";
                        Alert.error(message);
                        if (onError) {
                            onError(error_1, { value: value, message: message });
                        }
                        return [3 /*break*/, 5];
                    case 5:
                        if (dataURL) {
                            (_a = signatureRef.current) === null || _a === void 0 ? void 0 : _a.fromDataURL(dataURL);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        if (signatureRef.current && value !== signatureRef.current.toDataURL()) {
            initializeCanvas();
        }
    }, [value, retryAt, transformUrl, onError]);
    return (React.createElement(SignatureContainer, { disabled: disabled || hasError },
        React.createElement(Header, null,
            React.createElement(FieldLabel, { disabled: disabled },
                label,
                React.createElement("input", { type: "text", hidden: true, value: value, disabled: disabled || hasError, onChange: function (event) {
                        // Input just for controlling the Signature value from test environment
                        onChange(event.currentTarget.value);
                    } })),
            React.createElement(ButtonLayoutWrapper, null,
                React.createElement(DisplayWrapper, { isVisible: hasError },
                    React.createElement(Button, { outline: true, color: "danger", size: "small", icon: "arrows-cw", onClick: function () { return setRetryAt(new Date().toISOString()); } }, "Reload")),
                React.createElement(DisplayWrapper, { isVisible: !disabled && !hasError && (!isEmpty || isTouched) },
                    React.createElement(Button, { outline: true, color: "danger", size: "small", icon: "close", onClick: function () {
                            signatureRef.current.clear();
                            setIsTouched(false);
                            onChange("");
                        } }, "Clear")))),
        React.createElement(SignatureCanvasContainer, { "data-test-id": testId, id: "signature", filled: !isEmpty || isTouched, disabled: disabled || hasError },
            hasError && (React.createElement(PlaceholderMessage, null,
                React.createElement(Text, { color: "deepOrange100", fontSize: 4 }, "Unable to load signature, please try to reload."))),
            isEmpty && !isTouched && React.createElement(EmptySignatureMessage, null),
            React.createElement(SignaturePad, { ref: signatureRef, redrawOnResize: true, options: options }))));
};
Signature.defaultProps = {
    value: "",
    disabled: false,
    transformUrl: function (val) { return val; },
    options: {
        minWidth: 2,
        maxWidth: 3,
        dotSize: 3,
        velocityFilterWeight: 0.1,
    },
};
